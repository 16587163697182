/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:d7db142d-ab0f-4879-b10f-7704d59d9756",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_wHouydeTX",
    "aws_user_pools_web_client_id": "2iq7823g2tgse7uvfd29iqhtln",
    "oauth": {
        "domain": "corpusflower4cdf92165-cdf92165-devfour.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://corpusflower.com/",
        "redirectSignOut": "https://corpusflower.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS"
};


export default awsmobile;
